import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";

import "./styles/quote.scss";
import "./styles/blueCircle.scss";

const PageQuote = ({
  quote,
  quoteAuthor,
  quoteType,
  quoteHeadshot,
  quoteImg,
  quoteMobileImg,
  quoteAuthorAttribute,
  ProgramName,
  ProgramFacultyLink,
  quoteTwo,
  quoteTwoAuthor,
  quoteTwoHeadshot,
  quoteTwoAuthorAttribute,
  width,
}) => {
  if (quoteType === "alumni") {
    return (
      <div id="alumniPageQuote">
        <Container fluid="lg" className="py-5">
          <Row className="alumni-quote align-items-center justify-content-lg-between py-md-5 py-3">
            <Col
              xs={12}
              xl={6}
              lg={7}
              sm={8}
              className="mb-md-4 md-0 px-4 order-last order-sm-first quote-text-block"
            >
              <Row>
                <Col xs={12} className="mb-0">
                  <span className="blue-circle me-n3 mb-md-5 mb-3">
                    <FontAwesomeIcon
                      icon={faQuoteRight}
                      color="white"
                      size="6x"
                      fixedWidth
                    />
                  </span>
                  <div className="quote mb-4 mb-md-5">"{quote}"</div>
                  <Row>
                    <Col>
                      <h5>
                        {quoteAuthor}{" "}
                        <span className="ms-3 grey-grad">
                          — Meridian Graduate
                        </span>
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4} className="mb-4 px-4 ms-lg-4 mt-sm-5 mt-xl-0">
              <Row>
                <Col xs={12}>
                  <div className="img-bg-block"></div>
                  {quoteHeadshot && (
                    <img
                      src={quoteHeadshot}
                      alt={`Headshot of ${quoteAuthor}`}
                      className="img-fluid stacked-img"
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "alumni-reverse") {
    return (
      <div id="alumniPageQuoteReverse">
        <Container fluid="lg" className="py-lg-5">
          <Row className="alumni-quote align-items-center justify-content-lg-between justify-content-md-around py-md-5 py-3">
            <Col
              xs={12}
              xl={4}
              md={5}
              className="mb-4 px-4 mt-5 mt-md-0 order-md-first order-xs-last me-xl-5"
            >
              <Row>
                <Col xs={12}>
                  <div className="img-bg-block"></div>
                  {quoteHeadshot && (
                    <img
                      src={quoteHeadshot}
                      alt={`Headshot of ${quoteAuthor}`}
                      className="img-fluid stacked-img"
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={6}
              xl={7}
              className="mb-lg-4 md-0 px-4 quote-text-block"
            >
              <Row>
                <Col xs={12} className="mb-0">
                  <span className="blue-circle me-n3 mb-lg-5 mb-md-4 mb-3 d-none d-xl-inline-block">
                    <svg
                      width="31"
                      height="24"
                      viewBox="0 0 31 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.9706 0.249023V9.63593C12.9706 16.8803 8.22399 21.7903 1.54235 23.1098L0.276503 20.378C3.37051 19.2133 5.35897 15.7575 5.35897 12.9495H0.270142V0.249023H12.9706ZM30.7512 0.249023V9.63593C30.7512 16.8803 25.9892 21.7915 19.3162 23.1098L18.0508 20.378C21.142 19.2133 23.1279 15.7575 23.1279 12.9495H18.0673V0.249023H30.7512Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="quote mb-4 mb-lg-5">"{quote}"</div>
                  <Row>
                    <Col>
                      <h5>
                        {quoteAuthor}
                        <br />
                        <span className="ms-3 grey-grad">
                          {quoteAuthorAttribute}
                        </span>
                      </h5>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "program") {
    return (
      <div id="alumniPageQuote" className="content-quote program">
        <Container fluid>
          <Row className="alumni-quote align-items-center justify-content-xl-between justify-content-center">
            <Col
              xs={12}
              xl={6}
              className="p-xxl-5 p-4 order-last quote-text-block"
            >
              <div className="m-4 mx-xxl-5">
                <Row>
                  <Col xs={12} className="mb-4">
                    <span className="blue-circle me-n3">
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        color="white"
                        size="6x"
                        fixedWidth
                      />
                    </span>
                    {quoteHeadshot && (
                      <img
                        src={quoteHeadshot}
                        alt={`Headshot of ${quoteAuthor}`}
                        className="img-fluid img-rounded stacked-img"
                        width="100"
                        height="100"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-0">
                    <div className="quote mb-4">"{quote}"</div>
                    <Row>
                      <Col>
                        <h5>
                          {quoteAuthor}{" "}
                          <span className="ms-3 grey-grad">
                            {quoteAuthorAttribute}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              xs={12}
              xl={{ span: 4, offset: 1 }}
              className="p-4 p-xl-5 faculty"
            >
              <h2 className="mb-3">Program Faculty</h2>
              <p className="mb-3">
                Visit the {ProgramName} program's faculty page to review faculty
                names, biographies, and scholarship profiles via the
                University's Institutional repository.
              </p>
              <p>
                <Link to={ProgramFacultyLink} className="btn btn-primary">
                  Learn More{" "}
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "professionalInfo") {
    return (
      <div id="alumniPageQuote" className="content-quote program">
        <Container fluid>
          <Row className="alumni-quote align-items-center justify-content-xl-between justify-content-center">
            <Col
              xs={12}
              xl={6}
              xxl={{ span: 4, offset: 1 }}
              className="p-4 p-xxl-5 faculty"
            >
              <h2 className="mb-3">Next Steps</h2>
              <p className="mb-3">
                Are you contemplating integrating a specialty training program
                into your path towards a Master's or doctoral degree in
                psychology, business, coaching, facilitation, or leadership?
              </p>
              <p className="mb-3">
                With Meridian's Professional Specialty Program Pathways,
                academic enrichment goes beyond traditional boundaries. Discover
                more about enhancing your academic experience with PSPs and the
                potential opportunity to reduce tuition costs through the PSP
                Scholarship.
              </p>
              <p>
                <Link
                  to="/admissions/graduate-admissions/request-for-information"
                  className="btn btn-primary"
                >
                  CONTACT ADMISSIONS TEAM{" "}
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="#FFFFFF"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.77925 0.027832L6.7793 5.02783L1.77925 10.0278L0.360869 8.60935L3.94244 5.02783L0.360869 1.44631L1.77925 0.027832Z"
                      fill="#FFFFFF"
                    ></path>
                  </svg>
                </Link>
              </p>
            </Col>
            <Col xs={12} xl={6} className="px-0">
              <img
                src={quoteImg}
                alt="San Francisco City Street"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src={quoteMobileImg}
                alt="San Francisco City Street"
                className="img-fluid d-block d-xl-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "professionalQuote") {
    return (
      <div id="alumniPageQuote" className="content-quote program pro-quote">
        <Container fluid>
          <Row className="alumni-quote align-items-center justify-content-xl-between justify-content-center">
            <Col
              xs={12}
              xl={6}
              className="p-xxl-5 p-4 order-last quote-text-block"
            >
              <div className="m-4 mx-xxl-5">
                <Row>
                  <Col xs={12} className="mb-4">
                    <span className="blue-circle me-n3">
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        color="white"
                        size="6x"
                        fixedWidth
                      />
                    </span>
                    {quoteHeadshot && (
                      <img
                        src={quoteHeadshot}
                        alt={`Headshot of ${quoteAuthor}`}
                        className="img-fluid img-rounded stacked-img"
                        width="100"
                        height="100"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-0">
                    <div className="quote mb-4">"{quote}"</div>
                    <Row>
                      <Col>
                        <h5>
                          {quoteAuthor}{" "}
                          <span className="ms-3 grey-grad">
                            {quoteAuthorAttribute}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} xl={6} className="px-0">
              <img
                src={quoteImg}
                alt="Meridian Students"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src={quoteMobileImg}
                alt="Meridian Students"
                className="img-fluid d-block d-xl-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "page-quote") {
    return (
      <div id="alumniPageQuote" className="content-quote">
        <Container fluid>
          <Row className="alumni-quote align-items-center justify-content-xl-between justify-content-center">
            <Col
              xs={12}
              xl={6}
              className="p-4 order-last order-xl-first quote-text-block"
            >
              <div className="m-xl-0 m-4">
                <Row>
                  <Col xs={12} className="mb-4 mb-xl-2 mb-xxl-4 col-12">
                    <span className="green-circle me-n3">
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        color="white"
                        size="6x"
                        fixedWidth
                      />
                    </span>
                    {quoteHeadshot && (
                      <img
                        src={quoteHeadshot}
                        alt={`Headshot of ${quoteAuthor}`}
                        className="img-fluid img-rounded stacked-img"
                        width={width ? width : "100"}
                        height={width ? width : "100"}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-0">
                    <div className="quote mb-4 mb-xl-2 mb-xxl-4 col-12">
                      "{quote}"
                    </div>
                    <Row>
                      <Col>
                        <h5>
                          {quoteAuthor}{" "}
                          <span className="ms-3 grey-grad">
                            {quoteAuthorAttribute}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} xl={6} className="px-0">
              {quoteMobileImg != null ? (
                <>
                  <img
                    src={quoteImg}
                    alt="Meridian Students"
                    className="img-fluid d-none d-xl-block"
                  />
                  <img
                    src={quoteMobileImg}
                    alt="Meridian Students"
                    className="img-fluid d-block d-xl-none"
                  />
                </>
              ) : (
                <>
                  <img
                    src={quoteImg}
                    alt="Meridian Students"
                    className="img-fluid"
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "page-quote-reverse") {
    return (
      <div id="alumniPageQuote" className="content-quote reverse">
        <Container fluid>
          <Row className="alumni-quote align-items-center justify-content-xl-between justify-content-center">
            <Col xs={12} xl={6} className="p-4 order-last quote-text-block">
              <div className="m-4 mx-xxl-5 m-xl-3 my-xxl-4">
                <Row>
                  <Col xs={12} className="mb-4 mb-xl-2 mb-xxl-4">
                    <span className="green-circle me-n3">
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        color="white"
                        size="6x"
                        fixedWidth
                      />
                    </span>
                    {quoteHeadshot && (
                      <img
                        src={quoteHeadshot}
                        alt={`Headshot of ${quoteAuthor}`}
                        className="img-fluid img-rounded stacked-img"
                        width="100"
                        height="100"
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-0">
                    <div className="quote mb-4 mb-xl-2 mb-xxl-4">"{quote}"</div>
                    <Row>
                      <Col>
                        <h5>
                          {quoteAuthor}{" "}
                          <span className="ms-3 grey-grad">
                            {quoteAuthorAttribute}
                          </span>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} xl={6} className="px-0">
              <img
                src={quoteImg}
                alt="Meridian Students"
                className="img-fluid d-none d-xl-block"
              />
              <img
                src={quoteMobileImg}
                alt="Meridian Students"
                className="img-fluid d-block d-xl-none"
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
  if (quoteType === "two-quotes") {
    return (
      <div id="alumniPageQuote" className="content-quote two-quotes">
        <Container fluid>
          <div className="py-lg-5 py-5">
            <Row className="alumni-quote align-items-xl-center justify-content-xl-between justify-content-center p-lg-5 p-4">
              <Col
                xs={12}
                md={6}
                className="p-4 px-xl-5 quote-text-block border-end"
              >
                <div className="m-xl-4">
                  <Row>
                    <Col xs={12} className="mb-4">
                      <span className="green-circle me-n3">
                        <FontAwesomeIcon
                          icon={faQuoteRight}
                          color="white"
                          size="6x"
                          fixedWidth
                        />
                      </span>
                      {quoteHeadshot && (
                        <img
                          src={quoteHeadshot}
                          alt={`Headshot of ${quoteAuthor}`}
                          className="img-fluid img-rounded stacked-img"
                          width="100"
                          height="100"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mb-0">
                      <div className="quote mb-4">"{quote}"</div>
                      <Row>
                        <Col>
                          <h5>
                            {quoteAuthor}{" "}
                            <span className="ms-3 grey-grad">
                              {quoteAuthorAttribute}
                            </span>
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col xs={12} md={6} className="p-4 px-xl-5 quote-text-block">
                <div className="m-xl-4">
                  <Row>
                    <Col xs={12} className="mb-4">
                      <span className="green-circle me-n3">
                        <FontAwesomeIcon
                          icon={faQuoteRight}
                          color="white"
                          size="6x"
                          fixedWidth
                        />
                      </span>
                      {quoteTwoHeadshot && (
                        <img
                          src={quoteTwoHeadshot}
                          alt={`Headshot of ${quoteTwoAuthor}`}
                          className="img-fluid img-rounded stacked-img"
                          width="100"
                          height="100"
                        />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} className="mb-0">
                      <div className="quote mb-4">"{quoteTwo}"</div>
                      <Row>
                        <Col>
                          <h5>
                            {quoteTwoAuthor}{" "}
                            <span className="ms-3 grey-grad">
                              {quoteTwoAuthorAttribute}
                            </span>
                          </h5>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  } else {
    return (
      <div id="pageQuote">
        <Container fluid="lg">
          <Row className="align-items-center justify-content-center">
            <Col xl={11} className="page-quote text-center">
              <h1 className="quote">{quote}</h1>
              <h2 className="author">—{quoteAuthor}</h2>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};

export default PageQuote;
